import { createSignal, createResource, Suspense, For, Show } from "solid-js";
import { useParams } from "@solidjs/router";
import { Button, Avatar, Typography, IconButton, Stack, Card, Alert, Popover, Grid, Divider, Box } from "@suid/material";
import { AvatarWithWfButtons, showWfCandidateCV, showWfCandidateCustomerItems } from '../SmallComponents/SmallAvatarWithWfButtons'
import { CardWithMinimize } from '../AppMenuBar/CardList'

import { FieldAddress } from "../SmallComponents/SmallFieldAddress";
import { LooksOneOutlinedIcon, LooksTwoOutlinedIcon , OpenListIcon, CloseListIcon } from "../icons"
import { ShowSocialMedia } from '../SmallComponents/SmallSocialMedia';

import { useNavigate } from "@solidjs/router";
import { Tags } from '../SmallComponents/SmallTagsComponent';
import { MainLeftButtons } from '../Workflow/WfActionsComponent';
import { CandidateRatings } from '../Candidate/CandidateRatingComponent';
import { IndustryList } from '../MediumComponents/IndustriesComponent';
import { AccountStatusChip } from '../SmallComponents/SmallChips';
import { PageListMenu } from '../SmallComponents/SmallListMenu';
import { customerCandidateMenu } from "../../../utils/menuUtil" 
import { CompetenceList } from '../SmallComponents/SmallChipsCompetence'
import { formatDateByCountry } from "../../../utils/datetimeflag"

import { isExtraLarge } from "../../../utils/sizeUtil"

import { getDummyAvatar } from '../../../utils/getdummyavatar'
import { RateValueForTable } from '../SmallComponents/SmallRateValue';
import { getText, GetTextUI } from '../SmallComponents/Translate'

import { CandidateCVFetchType, CandidateAssignmentsFetchType, CandidateCompetenciesFetchType } from "../../../types/candidateCVDataTypes"


const apiUrl = import.meta.env.VITE_APP_API_URL;

const countryCode = "DK"

const fetchCV = async (candidateId: string | null): Promise<CandidateCVFetchType> => {
    const response = await fetch(`${apiUrl}/candidate/cv/${candidateId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('CV response was not ok.');
    }
    const data = await response.json();
    return data;
}
const fetchAssignments = async (candidateId: string | null): Promise<CandidateAssignmentsFetchType> => {
    const response = await fetch(`${apiUrl}/candidate/assignments/${candidateId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Assignments response was not ok');
    }
    const data = await response.json();
    return data;
}
const fetchCompetencies = async (candidateId: string | null): Promise<CandidateCompetenciesFetchType> => {
    const response = await fetch(`${apiUrl}/candidate/competencies/${candidateId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Competencies response was not ok');
    }
    const data = await response.json();
    return data;
}

const CustomerCandidateComponent = () => {

    const params = useParams();

    const fetchId = params.id ? params.id : "0";
    const [cv] = createResource<CandidateCVFetchType>(() => fetchCV(fetchId));
    const [assignment] = createResource<CandidateAssignmentsFetchType>(() => fetchAssignments(fetchId));
    const [competencies] = createResource<CandidateCompetenciesFetchType>(() => fetchCompetencies(fetchId));

    const checkSize = isExtraLarge()

    const [open, setOpen] = createSignal(false);

    const navigate = useNavigate();
    const handleOpen = () => {
        setOpen(true);
        navigate('/customerassignment')

    }

    const handleClose = () => setOpen(false);
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    const openPopover = () => Boolean(anchorEl());
    const handleOpenPopover = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const [openFilter, setOpenFilter] = createSignal(false);
    const handleOpenFilter = () => {
        alert('handleOpenFilter')
        setOpenFilter(true);
    }

    const handleNavigateRoute = (name: string) => {
        navigate(name);
    }
    const handleOpenAssignment = () => {
        setOpen(true);
        handleNavigateRoute('/candidateassignment')
    }

    const [firstName, setFirstName] = createSignal("Michael")
    const [lastName, setLastName] = createSignal("Scott")


    const txtOnsiteaddress = getText("jobpage", "onsiteaddress")
    const txtAccountAcitve = getText("candidatepage", "accountactive")
    const txtAlert1 = getText("candidatepage", "alert1", [{ name: "Name", value: "Tom" }])
    const txtAlert2 = getText("candidatepage", "alert2")
    const txtAssignmentInfo = getText("candidatepage", "assignmentinfo")

    const txtReferences = getText("jobpage", "jobreference")
    const txtCurrent = getText("candidatepage", "currentassignments")
    const txtHistory = getText("candidatepage", "history")
    const txtLanguages = getText("default", "languages")
    const txtMethods = getText("default", "methods")
    const txtRoles = getText("default", "roles")
    const txtTechnical = getText("default", "technical")
    const txtAccountVerified = getText("candidatepage", "accountverified")
    const txtAccountNotVerified = getText("candidatepage", "accountnotverified")
    const txtJobs = getText("quicklauncher", "jobs")


    const [showMore, setShowMore] = createSignal(false);
    const handleShowMore = () => {
        setShowMore(!showMore())
        handleOpenAssignment()
    }
    const txtMore = getText("default", "more")
    const txtLess = getText("default", "less")

 
    const txtAssignmentHistory = getText("candidatepage", "assignment", [{ name: "Name", value: firstName() },
    { name: "StartDate", value: "1-6-22" }, { name: "JobDuration", value: "6 months" },
    { name: "JobRole", value: "Project Manager" },
    { name: "JobName", value: "Concur project- Phase 1" },])
    const txtPaymentHistory = getText("candidatepage", "payment", [{ name: "Name", value: firstName() },
    { name: "Payment", value: "DKK 11.000,-/day including travel & accomendation" },])
    const txtInterviewHistory = getText("candidatepage", "negativeinterview", [{ name: "Name", value: firstName() },
    { name: "InterviewDate", value: "23-3-21" }, { name: "JobRole", value: "Project Manager" },
    { name: "JobName", value: "HR upgrade Oslo" }])

    return (
        <>
            {/* Present Candidate Profile section for Customer */}

            <Card sx={{ margin: 1 }}>

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>
                    <For each={cv()?.candidate}>
                        {(item, idx) => (
                            <>
                                <Grid container   >

                                    <Grid item xs={12} md={6}  >
                                        <Stack direction="row" justifyContent="space-between" >

                                            {/* Addition navigation options for the customer */}

                                            <PageListMenu options={customerCandidateMenu} handleEvent={handleOpenFilter} />
                                            <MainLeftButtons />

                                            {/* Present Candidates account status */}
                                            <AccountStatusChip >{item.verified ? txtAccountVerified() : txtAccountNotVerified() as string}</AccountStatusChip>
                                        </Stack>

                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <Stack direction="row" justifyContent="right" alignContent="center" margin={1} spacing={1}>
                                            <Tags />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>


                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                        <AvatarWithWfButtons src={item?.src} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={4} xl={3}>
                                        <br />
                                        <Typography variant="h4">{item?.firstName} {item.lastName}</Typography>
                                        <Typography variant="h5">{item?.title}</Typography>
                                        <Typography  >{item?.education}</Typography>
                                        {/* Candidates location */}
                                        <FieldAddress type="view"
                                            id="adresse" label={txtOnsiteaddress()} startValue={(item?.country + ", " + item?.city)}
                                        />
                                        <Stack direction="row" spacing={0.5}>
                                            <ShowSocialMedia SoMes={cv()?.candidateSOMEs} />
                                        </Stack>


                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={5} xl={6}>

                                        {/* Candidates main profile description */}
                                        <Popover
                                            id="mouse-over-popover" sx={{ pointerEvents: "none" }}
                                            open={openPopover()}
                                            anchorEl={anchorEl()}
                                            anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
                                            transformOrigin={{ vertical: "top", horizontal: "left", }}
                                            onClose={handleClosePopover}
                                            disableRestoreFocus
                                        >
                                            <Stack direction="column" spacing={2} margin={2}>

                                                <Typography color="text.primary">
                                                    <GetTextUI formName={"candidatepage"} label={"aidisclaimer"} />
                                                </Typography>
                                                <Divider />
                                                <Typography color="text.secondary"> <GetTextUI formName={"candidatepage"} label={"originaltext"} /> </Typography>
                                                <Card>
                                                    <Stack direction="column" spacing={1} margin={1}>
                                                        <Typography fontSize="80%" color="text.secondary">
                                                            {item?.description}
                                                        </Typography>
                                                    </Stack>
                                                </Card>

                                            </Stack>
                                        </Popover>
                                        <Stack direction="column" spacing={1} margin={1}>
                                            <div style={{ position: 'relative' }}>
                                                <Typography
                                                    aria-owns={openPopover() ? "mouse-over-popover" : undefined}
                                                    aria-haspopup="true"
                                                    color="text.secondary" onMouseEnter={handleOpenPopover} onMouseLeave={handleClosePopover} >
                                                    <br />
                                                    {item?.description}
                                                </Typography>

                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 0,
                                                    opacity: 0.4
                                                }}>
                                                    <Avatar src="/assets/imgs/aigenerative2.png"></Avatar>
                                                </div>
                                            </div>


                                            {/* Present Candidate rating for Customer */}
                                            <br />
                                            <CandidateRatings rating={3.5} numberOfFeedbacks={1} />
                                        </Stack>
                                    </Grid>

                                </Grid>

                                <Divider />

                                {/* Relevant info for the candidate's other activities */}
                                <Show
                                    when={cv()?.candidateCustomers?.length && cv()?.candidateIndustries?.length && competencies()?.languages?.length && competencies()?.methods?.length && competencies()?.technicals?.length}
                                    fallback={
                                        <>
                                            <Alert sx={{ px: 1, py: 1 }} severity="warning">
                                                {item.firstName} <GetTextUI formName={"candidatepage"} label={"alert3"} /> <br /><br />
                                                <Button><GetTextUI formName={"candidatepage"} label={"alert4"} /></Button>
                                            </Alert>
                                        </>

                                    }

                                >
                                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                                        {item.firstName} <GetTextUI formName={"candidatepage"} label={"alert1"} />. <br />
                                        <GetTextUI formName={"candidatepage"} label={"alert2"} />
                                    </Alert>
                                </Show>
                            </>
                        )}
                    </For>


                    <Divider />



                    <Suspense fallback={
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            <GetTextUI formName={"default"} label={"loading"} />.
                        </Alert>
                    }>
                        <Show when={assignment()?.assignments?.length}>
                            <CardWithMinimize level={1} header={txtCurrent() + " (" + assignment()?.assignments?.length + ")" as string} type="stack" defaultOpen={true}>
                                <Grid container spacing={1} >
                                    {/* Show each of the two Grid items in a row if screen is equal or larger than a medium screen */}
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {/* Candidates current assignment for the customer */}

                                        <For each={assignment()?.assignments}>
                                            {(item, idx) => (
                                                <>
                                                    <CardWithMinimize iconName={String(idx() + 1)} header={txtAssignmentInfo() as string} type="stack" defaultOpen={true}>

                                                        <Stack direction="column" spacing={1} margin={1}>
                                                            <Stack direction="row" >
                                                                <Typography color="text.secondary">
                                                                    {cv()?.candidate?.[0]?.firstName}

                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentstarted"} />
                                                                    {formatDateByCountry(item?.jobStartDate)}

                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentassignedfor"} />
                                                                    {formatDateByCountry(item?.jobEndDate)}

                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentassignedas"} />
                                                                    {item?.role}

                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentforthe"} />
                                                                    {item?.jobName}.
                                                                </Typography>
                                                            </Stack>
                                                            <Stack direction="row" alignItems="center" >
                                                                <Typography color="text.secondary">
                                                                    {cv()?.candidate?.[0]?.firstName}
                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentispaid"} />
                                                                </Typography>
                                                                <Show when={item?.rateFlat}>
                                                                    <RateValueForTable displayType="smallGreen" value={Number(item?.rateFlat)} country={countryCode} currency={item?.currency} primaryRateModel={"hour"} /><Typography color="text.secondary"> (<GetTextUI formName={"smallcomponent"} label={"flatrate"} />)  </Typography>
                                                                </Show>
                                                                <Show when={item?.rateOnsite}>
                                                                    <RateValueForTable displayType="smallGreen" value={Number(item?.rateOnsite)} country={countryCode} currency={item?.currency} primaryRateModel={"hour"} /><Typography color="text.secondary">  (<GetTextUI formName={"smallcomponent"} label={"onsite"} />)  </Typography>
                                                                </Show>
                                                                <Show when={item?.rateRemote}>
                                                                    <RateValueForTable displayType="smallGreen" value={Number(item?.rateRemote)} country={countryCode} currency={item?.currency} primaryRateModel={"hour"} /><Typography color="text.secondary">  (<GetTextUI formName={"smallcomponent"} label={"remote"} />)  </Typography>
                                                                </Show>
                                                            </Stack>

                                                            <Stack direction="row" spacing={1}>
                                                                <CandidateRatings showWithFilter={false} rating={4.5} numberOfFeedbacks={1} />
                                                            </Stack>

                                                            <Box alignContent="left">
                                                                <Button onClick={handleShowMore} color="inherit" size="small" variant="text" startIcon={showMore() ? <OpenListIcon /> : <CloseListIcon />}>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        {showMore() ? txtLess() : txtMore()}
                                                                    </Typography>
                                                                </Button>
                                                            </Box>
                                                        </Stack>
                                                    </CardWithMinimize>
                                                </>
                                            )}
                                        </For>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithMinimize header={txtHistory() as string + " (" + assignment()?.feedbacksAskFor?.length + ")"} type="stack" defaultOpen={true}>

                                            <Stack direction="column" spacing={1} sx={{ px: 2 }}>
                                                <IconButton onClick={handleOpen}><LooksOneOutlinedIcon /></IconButton>
                                                <Typography color="text.secondary">{txtAssignmentHistory()}</Typography>
                                                <Typography color="text.secondary">{txtPaymentHistory()} </Typography>

                                                <Stack direction="row" spacing={1}>
                                                    <CandidateRatings rating={0} numberOfFeedbacks={0} />
                                                </Stack>
                                                <Divider variant="inset" />

                                                <IconButton onClick={handleOpen}><LooksTwoOutlinedIcon /></IconButton>
                                                <Typography color="text.secondary">{txtInterviewHistory()}</Typography>

                                            </Stack>
                                        </CardWithMinimize>
                                    </Grid>
                                </Grid>
                            </CardWithMinimize>
                        </Show>
                    </Suspense>

                    {/* Industry list */}

                    <Show
                        when={cv()?.candidateIndustries?.length}
                    >
                        <Card sx={{ margin: 2, display: 'flex' }}>
                            <IndustryList valueList={cv()?.candidateIndustries} />
                        </Card>
                    </Show>

                    {/* List of Candidates different types of competencies */}

                    <Show
                        when={competencies()?.languages?.length || competencies()?.methods?.length || competencies()?.roles?.length || competencies()?.technicals?.length}
                    >
                        <Grid container spacing={1} >
                            <Grid item xs={12} md={6}>
                                <CompetenceList showAddButton={false} type="language" direction="row" header={txtLanguages() as string} options={competencies()?.languages} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CompetenceList showAddButton={false} type="method" direction="row" header={txtMethods() as string} options={competencies()?.methods} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CompetenceList showAddButton={false} type="role" direction="row" header={txtRoles() as string} options={competencies()?.roles} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CompetenceList showAddButton={false} type="technical" direction="row" header={txtTechnical() as string} options={competencies()?.technicals} />
                            </Grid>
                        </Grid>
                    </Show>

                    {/* List of customers at the bottom of the CV profile page */}
                    <Show
                        when={cv()?.candidateCustomers?.length}
                    >
                        <Suspense fallback={
                            <Alert sx={{ px: 1, py: 1 }} severity="info">
                                <GetTextUI formName={"default"} label={"loading"} />.
                            </Alert>}>

                            <CardWithMinimize level={1} header={txtReferences() + " (" + cv()?.candidateCustomers?.length + ")" as string} type="stack" defaultOpen={true}>

                                <For each={cv()?.candidateCustomers}>
                                    {(item) => (
                                        <>
                                            <AvatarWithWfButtons
                                                avatarSize="medium"
                                                showWfItems={showWfCandidateCV}
                                                avatarName={item.avatarName}
                                                jobCustomer={item.jobCustomer}
                                                jobName={item.jobName}
                                                jobDescription={item.jobDescription}
                                                locationCountry={item.jobCountry}
                                                locationCity={item.jobCity}
                                                jobAccomplishment={item.jobAccomplishment}
                                                jobSize={item.jobSize}
                                                anonymized={!item?.anonymized || item?.anonymized !== "nameAndAvatar" ? "no" : "nameAndAvatar"}
                                                rating={item.rating}
                                                numberOfRatings={item.numberOfRatings}
                                                jobStartDate={formatDateByCountry(item.jobStartDate)}
                                                jobEndDate={formatDateByCountry(item.jobEndDate)}
                                                role={item.role}
                                                roleDescription={item.roleDescription}
                                                src={item?.src ? item?.src : getDummyAvatar()}
                                            />
                                        </>
                                    )}
                                </For>
                            </CardWithMinimize>
                        </Suspense>
                    </Show>
                </Suspense>

            </Card>



        </>
    );
}
export { CustomerCandidateComponent }